import {
  Cube,
  ShareNetwork,
  CaretLeft,
  BookOpenText,
  ClockCountdown,
  Swatches,
  HeadCircuit,
  PlayCircle,
  AddressBook,
  Student,
  Target,
  Microphone,
  ChatTeardrop,
  ShareFat,
  HeartHalf,
  DownloadSimple,
  Queue,
  ShieldStar,
  Lightning,
  ArrowLeft,
} from "@phosphor-icons/react";
import { Button, Col, Flex, Popconfirm, Row, Space, Typography } from "antd";
import { useAntTheme } from "hooks/useAntTheme";
import {
  useTranslate,
  useCustomMutation,
  useGo,
  useInvalidate,
} from "@refinedev/core";
import { useNavigate } from "react-router-dom";
import { ViewExperienceButton } from "./ViewExperienceButton";
import { MediaProjectResponse } from "../../types";
import { FatButton } from "components/FatButton";
import { MenuItem } from "../types";
import { useVolatileBoundStore } from "store";
import styled from "styled-components";
import { SettingOutlined } from "@ant-design/icons";
import { Trans } from "react-i18next";

export const ProjectPageMenu = ({
  project,
}: {
  project: MediaProjectResponse;
}) => {
  const navigate = useNavigate();
  const t = useTranslate();
  const { theme } = useAntTheme();
  const invalidate = useInvalidate();
  const go = useGo();

  const { currentMenuIndex, setCurrentMenuIndex } = useVolatileBoundStore(
    (store) => ({
      currentMenuIndex: store.projectState.currentMenuIndex,
      setCurrentMenuIndex: store.setCurrentMenuIndex,
    })
  );

  const hasMedia = Boolean(project?.media?.length);
  const editingMedia =
    project?.media?.filter((media) => media.status !== "Published") || [];
  const publishedPreviewedMedia =
    project?.media?.filter(
      (media) => media.status === "Published" || media.status === "Preview"
    ) || [];
  const media = project.media?.[0];

  const isMediaReadyForEdit = ["Published", "Ready", "Preview"].includes(
    media?.status
  );

  const { mutateAsync: approveSettings } = useCustomMutation({});

  const subMenuEdit: MenuItem[] = [
    {
      icon: <BookOpenText size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.editStoryboard"),
      content: t("media.projects.projectMenu.reviewContentOfVideo"),
      route: `/media/projects/${project.id}/storyboard/${project?.storyboards?.[0]?.asset_id}`,
      id: 1,
    },
    {
      icon: <ClockCountdown size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.documentAnimation"),
      content: t("media.projects.projectMenu.synchronizeVideo"),
      route: `/media/projects/${project.id}/media/${editingMedia?.[0]?.id}/animation`,
      id: 2,
      hide: !isMediaReadyForEdit,
      supercharge: true,
    },
    {
      icon: <Swatches size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.changeBrandKit"),
      route: `/media/projects/${project.id}/settings/brandkit`,
      id: 3,
    },
    {
      icon: <HeadCircuit size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.editKnowledgeBase"),
      route: `/media/projects/${project.id}/kb`,
      id: 4,
      supercharge: true,
    },
    {
      icon: <PlayCircle size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.editVideo"),
      route: `/media/projects/${project.id}/media/${editingMedia?.[0]?.id}/templates`,
      hide: !isMediaReadyForEdit,
      id: 5,
    },
    {
      icon: <AddressBook size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.editContact"),
      //route: `/media/projects/${project.id}/settings/contact`,
      route: `/media/contacts/${media.contact_id}/edit`,
      id: 6,
    },
    {
      icon: <ChatTeardrop size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.editChat"),
      id: 7,
      supercharge: true,
      hide: true,
    },
    {
      icon: <Target size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.editCtas"),
      route: `/media/projects/${project.id}/settings/ctas`,
      id: 8,
      supercharge: true,
    },
    {
      icon: <Student size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.editQuiz"),
      id: 9,
      hide: true,
      supercharge: true,
    },
    {
      icon: <Microphone size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.editPodcast"),
      id: 10,
      hide: true,
      supercharge: true,
    },
  ];

  const subMenuSupercharge: MenuItem[] = [
    {
      icon: <Queue size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.animatedDocument"),
      route: `/media/projects/${project.id}/feature/animation`,
      id: 5,
    },
    {
      icon: <Student size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.quiz"),
      id: 1,
      hide: true,
    },
    {
      icon: <Target size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.ctas"),
      route: `/media/projects/${project.id}/settings/ctas`,
      id: 2,
      hide: true,
    },
    {
      icon: <Microphone size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.podcast"),
      id: 3,
      hide: true,
    },
    {
      icon: <ChatTeardrop size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.chat"),
      route: `/media/projects/${project.id}/feature/chat`,
      id: 4,
    },
  ];

  const subMenuShare: MenuItem[] = [
    {
      icon: <ShareFat size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.shareExperience"),
      id: 1,
      route: `/media/projects/${project.id}/media/${media?.id}/share`,
    },
    {
      icon: <HeartHalf size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.analytics"),
      route: `/media/projects/${project.id}/analytics`,
      id: 2,
      hide: true,
    },
    {
      icon: <DownloadSimple size={32} weight="duotone" />,
      label: t("media.projects.projectMenu.download"),
      id: 3,
      route: `/media/projects/${project.id}/media/${editingMedia?.[0]?.id}/download`,
    },
  ];

  let menu: MenuItem[] = [
    {
      icon: <Cube size={44} weight="duotone" />,
      // icon: (
      //   <div style={{ position: "relative", width: 44 }}>
      //     <Cube size={44} weight="duotone" />
      //     <StarFour
      //       style={{ position: "absolute", left: 35, top: -2 }}
      //       weight="fill"
      //       size={14}
      //     />
      //   </div>
      // ),
      label: t("media.projects.projectMenu.edit"),
      content: (
        <Trans>
          {t("media.projects.projectMenu.editContent", { strong: <strong /> })}
        </Trans>
      ),
      id: 1,
      items: subMenuEdit,
    },
    {
      icon: <ShieldStar size={44} weight="duotone" />,
      label: t("media.projects.projectMenu.supercharge"),
      content: (
        <Trans>
          {t("media.projects.projectMenu.addSuperpowers", {
            strong: <strong />,
          })}
        </Trans>
      ),
      id: 2,
      items: subMenuSupercharge,
    },
    {
      icon: <ShareNetwork size={44} weight="duotone" />,
      label: t("media.projects.projectMenu.share"),
      content: t("media.projects.projectMenu.spreadTheWord"),
      id: 3,
      items: subMenuShare,
    },
  ];

  // const handleApprove = async () => {
  //   await approveSettings({
  //     url: `media/projects/${project.id}/settings/approve`,
  //     method: "post",
  //     values: {},
  //   });
  //   invalidate({
  //     resource: "media/projects",
  //     id: project?.id,
  //     invalidates: ["detail"],
  //   });
  // };

  //settings not approved
  if (
    project.settings.status === "Draft" &&
    project.storyboards[0].status === "WaitingUserInput"
  ) {
    menu = [
      {
        icon: <SettingOutlined style={{ fontSize: 44 }} />,
        label:
          //(
          // <Popconfirm
          //   title={t("projects.components.ProgressHelp.areYouSure")}
          //   onConfirm={handleApprove}
          // >
          t("media.projects.projectMenu.approveSettings"),
        //</Popconfirm>
        //),
        id: 1,
        route: `/media/projects/${project.id}/setup`,
      },
    ];
  }

  // new project waiting storyboard to be approved
  if (
    ["Generating", "Pending", "Reviewing"].includes(
      project?.storyboards?.[0]?.status
    ) &&
    media?.status === "Draft"
  ) {
    menu = [
      {
        icon: <BookOpenText size={44} weight="duotone" />,
        label: t("media.projects.projectMenu.editStoryboard"),
        content: t("media.projects.projectMenu.reviewContentOfVideo"),
        route:
          project?.storyboards?.[0]?.status === "Reviewing"
            ? `/media/projects/${project?.id}/storyboard/${project?.storyboards?.[0]?.asset_id}`
            : project?.storyboards?.[0]?.status === "Generating"
              ? `/media/projects/${project?.id}/storyboard/loading}`
              : "",
        id: 1,
        loading:
          project?.storyboards?.[0]?.status === "Reviewing" ? false : true,
      },
    ];
  }

  const handleButtonClickToRoute = (route) => {
    navigate(route);
    return true;
  };

  const renderMenuItems = (items: MenuItem[], colSpan: number) => {
    return (
      <>
        {items.map(
          (menuItem) =>
            !menuItem.hide && (
              <Col xl={colSpan} lg={24} key={menuItem.id}>
                <FatButton
                  block
                  color="secondary"
                  style={{ height: 140, minWidth: 200 }}
                  icon={menuItem.icon}
                  title={menuItem.label as string}
                  loading={menuItem.loading}
                  content={menuItem.content}
                  arrow={menuItem.items?.length > 0}
                  onClick={
                    menuItem.onClick ??
                    (() => handleButtonClickToRoute(menuItem.route))
                  }
                />
              </Col>
            )
        )}
      </>
    );
  };

  return (
    <>
      {currentMenuIndex === null && (
        <div
          style={{
            background: `radial-gradient(
            circle,
            rgba(255, 214, 0, 0.7),
            transparent 62%)`,
          }}
        >
          <CircleGradientBackground>
            <Flex
              align="center"
              gap={20}
              wrap
              style={{
                maxWidth: 250 * 2 + 20 + 36 * 2, // 2 cols
                // frosted glass effect
                backgroundColor: `${theme.colorBgBlur}`,
                borderRadius: 10,
                padding: 36,
              }}
            >
              {/* main menu */}
              {menu?.map((menuItem, index) => (
                <FatButton
                  color="secondary"
                  style={{ height: 160, width: 250 }}
                  key={menuItem.id}
                  icon={menuItem.icon}
                  title={menuItem.label as string}
                  loading={menuItem.loading}
                  content={menuItem.content}
                  arrow={menuItem.items?.length > 0}
                  onClick={() => {
                    return menuItem.items
                      ? setCurrentMenuIndex(index)
                      : handleButtonClickToRoute(menuItem.route);
                  }}
                />
              ))}
              {currentMenuIndex === null &&
                publishedPreviewedMedia?.[0]?.id && (
                  <ViewExperienceButton
                    projectId={project.id}
                    mediaStatus={media.status}
                  />
                )}
            </Flex>
          </CircleGradientBackground>
        </div>
      )}

      {/* sub menu container */}
      {!(currentMenuIndex === null) ? (
        <Flex
          vertical
          gap={32}
          justify="flex-start"
          style={{
            backgroundColor: theme.colorPrimaryBg,
            borderRadius: 10,
            padding: 24,
            width: !menu[currentMenuIndex]?.items?.some(
              (item) => item.supercharge
            )
              ? "calc(min(95%, 950px))"
              : "calc(min(95%, 1250px))",
          }}
        >
          <Flex justify="space-between" style={{ width: "100%" }}>
            <Button
              type="primary"
              shape="round"
              size="large"
              icon={
                <span className="anticon">
                  <ArrowLeft
                    size={24}
                    style={{ paddingTop: 0 }}
                    color={theme.colorText}
                  />
                </span>
              }
              onClick={() => setCurrentMenuIndex(null)}
            >
              <Typography.Text>
                {t("media.projects.projectMenu.back")}
              </Typography.Text>
            </Button>
            <Flex align="center" gap={10}>
              {menu[currentMenuIndex].icon}
              <Flex vertical>
                <Typography.Text strong style={{ fontSize: 20 }}>
                  {menu[currentMenuIndex].label}
                </Typography.Text>
                <Typography.Text
                  type="secondary"
                  style={{
                    fontSize: 14,
                  }}
                >
                  {menu[currentMenuIndex].content}
                </Typography.Text>
              </Flex>
            </Flex>
            <div style={{ width: 100 }}></div>
          </Flex>
          {!menu[currentMenuIndex]?.items.some((item) => item.supercharge) ? (
            // if no supercharge menu items
            <Row gutter={[40, 15]}>
              {menu[currentMenuIndex].items &&
                renderMenuItems(menu[currentMenuIndex].items, 8)}
            </Row>
          ) : (
            // if there are supercharge menu items

            <Row gutter={[16, 16]}>
              <Flex justify="center">
                <Col span={11}>
                  <Flex vertical align="center" gap={12}>
                    <Space style={{ height: 20 }}>
                      <Typography.Text
                        strong
                        style={{
                          fontSize: 12,
                          letterSpacing: 4,
                          textTransform: "uppercase",
                        }}
                      >
                        {t("media.projects.projectMenu.basic")}
                      </Typography.Text>
                    </Space>
                    <Row gutter={[16, 16]}>
                      {menu[currentMenuIndex].items &&
                        renderMenuItems(
                          menu[currentMenuIndex].items.filter(
                            (menuItem) => !menuItem.supercharge
                          ),
                          12 //colSpan
                        )}
                    </Row>
                  </Flex>
                </Col>
                <Col span={2}>
                  <div
                    style={{
                      margin: "auto",
                      width: 2,
                      height: "100%",
                      // backgroundImage: `linear-gradient(to bottom, #A276FF, ${theme.colorPrimary}`,
                      // from https://codepen.io/nxworld/pen/oGwxaP
                      backgroundImage: `linear-gradient(to bottom, transparent, transparent 50%, ${theme.colorPrimaryBg} 50%, ${theme.colorPrimaryBg} 100%), linear-gradient(to bottom, #A276FF,  ${theme.colorPrimary})`,
                      backgroundSize: "2px 10px, 2px 100%",
                    }}
                  />
                </Col>
                <Col span={11}>
                  <Flex vertical align="center" gap={12}>
                    <Space align="center" style={{ height: 20 }}>
                      <Lightning size={20} weight="fill" />
                      <Typography.Text
                        strong
                        style={{
                          fontSize: 12,
                          letterSpacing: 4,
                          textTransform: "uppercase",
                        }}
                      >
                        {t("media.projects.projectMenu.supercharge")}
                      </Typography.Text>
                    </Space>
                    <Row gutter={[16, 16]}>
                      {menu[currentMenuIndex].items &&
                        renderMenuItems(
                          menu[currentMenuIndex].items.filter(
                            (menuItem) => menuItem.supercharge
                          ),
                          12 //colSpan
                        )}
                    </Row>
                  </Flex>
                </Col>
              </Flex>
            </Row>
          )}
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

const CircleGradientBackground = styled.div`
  background: radial-gradient(
      circle at calc(35%) 50%,
      rgba(33, 177, 53, 0.7),
      transparent 48%
    ),
    radial-gradient(
      circle at calc(65%) 50%,
      rgba(73, 0, 229, 0.7),
      transparent 48%
    ),
    transparent;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
`;
