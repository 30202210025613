import { useTranslate } from "@refinedev/core";
import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import { Record, Stop } from "@phosphor-icons/react";
import {
  useCreate,
  useCustomMutation,
  useGo,
  useInvalidate,
  useOne,
} from "@refinedev/core";
import {
  Alert,
  Button,
  Flex,
  Input,
  Progress,
  Space,
  Typography,
  notification,
} from "antd";
import lamejs from "lamejs"; // Import lamejs
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import Dragger from "antd/es/upload/Dragger";
import { AppContext } from "appContext";
import { useMediaAssetUpload } from "hooks/useMediaAssetUpload";
import { useOrganization } from "hooks/useOrganization";
import { CustomFormItemProps } from "types";
import { AssetSource } from "../types";
import { VoiceProfileCreationResponse, VoiceProfileResponse } from "../types";
import { AudioSample } from "./AudioSample";
import { ENABLE_MP3_ENCODING } from "../constants";
import { EllipsisAnimated } from "components/EllipsisAnimated";

const DEFAULT_PROVIDER_ID = "high_quality_01";
const DEFAULT_VOICE_NAME = "Voice";
const MAX_UPLOADED_SAMPLE_DURATION = 120; // 2 minutes to avoid long audio files
// const MAX_TOTAL_DURATION = 120;
const MIN_TOTAL_DURATION = 30; // elevenlabs allow 30s (steve)
const MAX_SAMPLE_DURATION = 35;
const WAV_SAMPLE_RATE = 44100; // cf https://help.elevenlabs.io/hc/en-us/articles/15754340124305-What-audio-formats-do-you-support
const RECORD_COUNTDOWN_DURATION = 3; // seconds

export const VoiceProfileCreateForm: React.FC<CustomFormItemProps<string>> = ({
  onSuccess,
}) => {
  const t = useTranslate();
  const go = useGo();
  const invalidate = useInvalidate();
  const [recordCountdown, setRecordCountdown] = useState(
    RECORD_COUNTDOWN_DURATION
  );
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<RecordRTC | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [render, setRender] = useState(0);
  const [isCreating, setIsCreating] = useState(false);
  const [audioFiles, setAudioFiles] = useState<File[]>([]);
  const [uploadShown, setUploadShown] = useState(false);
  const recordingIntervalRef = useRef<NodeJS.Timeout>();

  const {
    state: { user },
  } = useContext(AppContext);
  const { organization } = useOrganization({});
  const [voiceName, setVoiceName] = useState(DEFAULT_VOICE_NAME);
  const { mutateAsync } = useCustomMutation({});

  const [newVoiceProfile, setNewVoiceProfile] =
    useState<VoiceProfileCreationResponse>();

  const { mutateAsync: mutateVoiceProfile } =
    useCreate<VoiceProfileCreationResponse>();

  const ref = useRef<{
    audios: HTMLAudioElement[];
    filesAudios: HTMLAudioElement[];
    recordedSamples: Blob[];
    uploadedSamples: File[];
    isPlaySound: boolean;
    isRecording?: boolean;
  }>({
    audios: [],
    filesAudios: [],
    recordedSamples: [],
    uploadedSamples: [],
    isPlaySound: false,
  }).current;

  const voiceProfile = useOne<VoiceProfileResponse>({
    resource: `media/${organization?.id}/voice_profiles`,
    id: newVoiceProfile?.voice_profile_id,
    queryOptions: {
      enabled: !!newVoiceProfile?.voice_profile_id,
      refetchInterval: (data) =>
        Object.keys(data?.data?.assets ?? {}).length ===
        ref.recordedSamples.length + audioFiles.length
          ? false
          : 1000,
    },
  });


  const loadSound = async (path: Blob): Promise<HTMLAudioElement> => {
    return new Promise((resolve) => {
      const sound = new Audio(URL.createObjectURL(path));
      sound.addEventListener("loadedmetadata", () => resolve(sound), false);
    });
  };

  // const playFileSound = async (index: number) => {
  //   ref.isPlaySound = true;
  //   setIsPlaySound(true);
  //   await ref.filesAudios[index].play();
  // };

  const startRecord = async () => {
    const newStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    setRecording(true);
    // reset for next time
    setRecordCountdown(RECORD_COUNTDOWN_DURATION);
    setRecordingDuration(0); // Reset the timer to 0
    setStream(newStream);
    const newMediaRecorder = new RecordRTC(newStream, {
      recorderType: StereoAudioRecorder,
      mimeType: "audio/wav",
      // timeSlice: 250,
      bufferSize: 4096,
      numberOfAudioChannels: 1,
    });
    setMediaRecorder(newMediaRecorder);
    newMediaRecorder?.startRecording();
  };
  useEffect(() => {
    let intervalId;

    if (isCountingDown && recordCountdown > 0) {
      intervalId = setInterval(() => {
        setRecordCountdown((prevTime) => prevTime - 1);
      }, 1000);
    } else if (recordCountdown === 0) {
      clearInterval(intervalId);
      setIsCountingDown(false);
      startRecord();
    }

    return () => clearInterval(intervalId);
  }, [isCountingDown, recordCountdown]);

  const handleStartRecording = () => {
    if (isCountingDown) return;
    setIsCountingDown(true);
    setRecordCountdown(RECORD_COUNTDOWN_DURATION);
  };
  const stopRecord = useCallback(() => {
    setRecording(false);
    if (!recording) return;
    mediaRecorder?.stopRecording(async () => {
      const blob = mediaRecorder?.getBlob();
      if (blob) {
        ref.recordedSamples.push(blob);
        ref.audios.push(await loadSound(blob));
        setRender(new Date().getTime()); // hack to rerender
      }

      stream?.getTracks().forEach((track) => track.stop());
      setRecordingDuration(0);
    });
  }, [recording, mediaRecorder, ref.audios, ref.recordedSamples, stream]);

  // https://github.com/zhuker/lamejs/issues/89
  const convertWavToMp3 = (wavBlob: Blob) => {
    return new Promise<Blob>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const wavBuffer = event.target?.result;
        console.debug({ wavBuffer });
        if (wavBuffer instanceof ArrayBuffer) {
          const wav = lamejs.WavHeader.readHeader(new DataView(wavBuffer));
          const recordedSamples = new Int16Array(
            wavBuffer,
            wav.dataOffset,
            wav.dataLen / 2
          );
          const mp3encoder = new lamejs.Mp3Encoder(1, WAV_SAMPLE_RATE, 128);
          const mp3Data: Int8Array[] = [];
          const sampleBlockSize = 1152;
          for (let i = 0; i < recordedSamples.length; i += sampleBlockSize) {
            const sampleChunk = recordedSamples.subarray(
              i,
              i + sampleBlockSize
            );
            const mp3buf = mp3encoder.encodeBuffer(sampleChunk);
            if (mp3buf.length > 0) {
              mp3Data.push(new Int8Array(mp3buf));
            }
          }
          const mp3buf = mp3encoder.flush();
          if (mp3buf.length > 0) {
            mp3Data.push(new Int8Array(mp3buf));
          }
          const mp3Blob = new Blob(mp3Data, { type: "audio/mp3" });
          resolve(mp3Blob);
        } else {
          reject(
            new Error("Expected ArrayBuffer but received " + typeof wavBuffer)
          );
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(wavBlob);
    });
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const calculateOverallDuration = () => {
    let totalDuration = 0;
    ref.audios.forEach((audio) => {
      totalDuration += Math.floor(audio.duration);
    });
    ref.filesAudios.forEach((audio) => {
      totalDuration += Math.floor(audio.duration);
    });
    return totalDuration;
  };

  const removeAudioSample = (index: number) => {
    ref.audios.splice(index, 1);
    ref.recordedSamples.splice(index, 1);
    setRender(new Date().getTime()); // hack to rerender
  };
  const removeFileSample = (index: number) => {
    ref.filesAudios.splice(index, 1);
    ref.uploadedSamples.splice(index, 1);
    setRender(new Date().getTime());
  };

  const { uploadFile, createFileFromBlob } = useMediaAssetUpload({
    organization: organization?.id,
    assetType: "Audio",
  });

  const createVoiceProfile = async () => {
    const createPayload = (file: File, newVoiceProfileId: string) =>
      [
        {},
        file,
        `/media/${organization?.id}/voice_profiles/${newVoiceProfileId}/assets/`,
        {
          type: "CreateVoiceAsset",
          organization_id: organization?.id,
          category: "Voice",
          source: AssetSource.UIGenerated,
          description: "",
          asset: {
            type: "Speech",
            file_name: file.name,
          },
        },
      ] as const;
    try {
      setIsCreating(true);
      // Create new voice profile entity
      const newVoiceProfileResponse = await mutateVoiceProfile({
        resource: `media/${organization?.id}/voice_profiles`,
        values: {
          name: voiceName,
          provider_id: DEFAULT_PROVIDER_ID,
          settings: {},
          category: "Cloned",
        },
        successNotification: {
          message: t("media.components.VoiceProfileCreateForm.yourVoiceIs"),
          type: "success",
          description: t("media.components.VoiceProfileCreateForm.success"),
        },
      });
      const newVoiceProfile =
        newVoiceProfileResponse.data as VoiceProfileCreationResponse;
      const { voice_profile_id: newVoiceProfileId } = newVoiceProfile;
      // Now we're ready to upload recordedSamples to it since that cannot be done
      // without first creating a VP entity.
      const assetIds = await Promise.all([
        ...ref.recordedSamples.map(async (audio, index) => {
          const filename = ENABLE_MP3_ENCODING
            ? `audiosample_${index}.mp3`
            : `audiosample_${index}.wav`;
          const blob = ENABLE_MP3_ENCODING
            ? await convertWavToMp3(audio)
            : audio;
          const mimeType = ENABLE_MP3_ENCODING ? "audio/mp3" : "audio/wav";

          const file = createFileFromBlob(blob, filename, mimeType);
          // This is the asset_id for the voice (not profile) entity
          // uploadFile takes care of creating the entities and uploading to S3
          const { asset_id } = await uploadFile(
            ...createPayload(file, newVoiceProfileId)
          );
          return asset_id;
        }),
        ...audioFiles.map(async (file) => {
          const { asset_id } = await uploadFile(
            ...createPayload(file, newVoiceProfileId)
          );
          return asset_id;
        }),
      ]);
      // Setting this will start polling the newly created VP
      setNewVoiceProfile(newVoiceProfile);
    } catch (error) {
      // Re-enable the button and reset its text after processing
      setIsCreating(false);
    }
  };

  // init voice name with current user first name
  useEffect(() => {
    user?.first_name && setVoiceName(user?.first_name);
  }, [user?.first_name]);

  useEffect(() => {
    if (recordingDuration >= MAX_SAMPLE_DURATION) {
      clearInterval(recordingIntervalRef.current);
      stopRecord(); // Stop recording when max duration is reached
    }
  }, [recordingDuration, stopRecord]);

  useEffect(() => {
    if (recording) {
      recordingIntervalRef.current = setInterval(() => {
        setRecordingDuration((prevTime) => prevTime + 1); // Incrementing time
      }, 1000);
    }
    return () => clearInterval(recordingIntervalRef.current);
  }, [recording]);

  useEffect(() => {
    ref.isRecording = recording;
  }, [ref, recording]);

  useEffect(() => {
    (async () => {
      if (!voiceProfile.isSuccess) return;
      // Wait until the voice profile has assets before trying to clone
      if (
        Object.keys(voiceProfile?.data?.data?.assets ?? {}).length ===
        ref.recordedSamples.length + ref.filesAudios.length
      ) {
        await mutateAsync({
          url: `/media/${organization?.id}/voice_profiles/${voiceProfile?.data?.data.id}/clone`,
          method: "post",
          values: {},
        });

        await invalidate({
          resource: `media/${organization?.id}/voice_profiles`,
          invalidates: ["list"],
        });
        // We need this timeout so that the user is able to see the notification
        // but there should be a better way?
        onSuccess
          ? onSuccess(voiceProfile?.data?.data)
          : setTimeout(() => {
              go({ to: { resource: "media_voices", action: "list" } });
            }, 3000);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voiceProfile?.data?.data?.assets]);

  const totalAudioSamplesDuration = calculateOverallDuration();
  const canCreate =
    voiceName.length > 0 && totalAudioSamplesDuration >= MIN_TOTAL_DURATION;

  return (
    <Flex vertical gap={30} style={{ maxWidth: 500 }}>
      <Alert
        // message="Audio samples"
        description={t("media.components.VoiceProfileCreateForm.toCreateA")}
        type="info"
        showIcon
      />
      <Flex vertical gap={5}>
        <Typography.Text>
          {t("media.components.VoiceProfileCreateForm.voiceName")}
        </Typography.Text>
        <Input
          size="large"
          value={voiceName}
          onChange={(e) => setVoiceName(e.target.value)}
        />
      </Flex>
      <Flex vertical>
        {totalAudioSamplesDuration > 0 && (
          <Typography.Title level={5} style={{ marginTop: 0 }}>
            {t("media.components.VoiceProfileCreateForm.audioSamples")}
          </Typography.Title>
        )}
        {ref.audios.map((audio, index) => (
          <AudioSample
            key={index}
            audio={audio}
            removeAudioSample={() => removeAudioSample(index)}
          >
            <Typography.Text ellipsis>{`Sample #${index + 1}`}</Typography.Text>
          </AudioSample>
        ))}
        {ref.filesAudios.map((audio, index) => (
          <AudioSample
            key={index}
            audio={audio}
            removeAudioSample={() => removeFileSample(index)}
          >
            <Typography.Text ellipsis>
              {`Sample #${ref.audios.length + index + 1}`}
            </Typography.Text>
          </AudioSample>
        ))}
        {totalAudioSamplesDuration > 0 && (
          <Flex style={{ marginTop: 10 }} justify="space-between">
            <Typography.Paragraph type="secondary">
              {t("media.components.VoiceProfileCreateForm.total")}
              {totalAudioSamplesDuration}
              {t("media.components.VoiceProfileCreateForm.seconds")}
            </Typography.Paragraph>
            <Typography.Paragraph type="secondary">
              {t("media.components.VoiceProfileCreateForm.minimum")}
              {MIN_TOTAL_DURATION}
              {t("media.components.VoiceProfileCreateForm.seconds")}
            </Typography.Paragraph>
          </Flex>
        )}
      </Flex>

      {recording && (
        <Flex vertical gap={0}>
          <Flex justify="space-between">
            {/* Red dot indicator */}
            <Space>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                }}
              />
              <Typography.Text>
                {t("media.components.VoiceProfileCreateForm.recording")}
                <EllipsisAnimated />
              </Typography.Text>
            </Space>
            <Button
              size="middle"
              shape="round"
              style={{ alignItems: "center", display: "flex" }}
              icon={<Stop weight="fill" size={"1em"} />}
              onClick={stopRecord}
            >
              {t("media.components.VoiceProfileCreateForm.stop")}
            </Button>
          </Flex>
          <Flex vertical>
            <Space>
              <Typography.Text style={{ fontSize: 24 }}>
                {formatTime(recordingDuration)}
              </Typography.Text>
              <Typography.Text type="secondary" style={{ fontSize: 24 }}>
                / {formatTime(MAX_SAMPLE_DURATION)}
              </Typography.Text>
            </Space>
            <Progress
              percent={(recordingDuration / MAX_SAMPLE_DURATION) * 100}
              showInfo={false}
              strokeColor={{
                "0%": "#C13BF1",
                "100%": "#4900E5",
              }}
            />
          </Flex>
        </Flex>
      )}

      {!recording && !(recordCountdown === 0) && (
        <Flex justify="space-between" gap={10}>
          <Button
            size="middle"
            shape="round"
            style={{ alignItems: "center", display: "flex" }}
            icon={<Record color="red" weight="fill" size={"1em"} />}
            onClick={handleStartRecording}
          >
            {isCountingDown
              ? `${t("media.components.VoiceProfileCreateForm.startingIn")} ${recordCountdown}`
              : t("media.components.VoiceProfileCreateForm.recordSample")}
          </Button>
          {!uploadShown && (
            <Button
              size="middle"
              shape="round"
              icon={<CloudUploadOutlined />}
              onClick={() => setUploadShown(true)}
            >
              {t("media.components.VoiceProfileCreateForm.uploadSamples")}
            </Button>
          )}
        </Flex>
      )}
      {uploadShown && (
        <Flex>
          <Dragger
            maxCount={5}
            iconRender={(_file) => null}
            showUploadList={false}
            accept=".wav,.mp3"
            customRequest={async ({ file }) => {
              const audio = await loadSound(file as Blob);

              if (audio.duration > MAX_UPLOADED_SAMPLE_DURATION) {
                const sampleDuration = Math.floor(audio.duration);
                notification.error({
                  message: t(
                    "media.components.VoiceProfileCreateForm.sampleTooLong"
                  ),
                  description: t(
                    "media.components.VoiceProfileCreateForm.sampleDuration",
                    {
                      duration: sampleDuration,
                      max: MAX_UPLOADED_SAMPLE_DURATION,
                    }
                  ),
                });
              } else {
                // valid file
                ref.filesAudios.push(audio);
                ref.uploadedSamples.push(file as File);
                setAudioFiles((current) => [...current, file as File]);
              }
            }}
          >
            <p>
              <UploadOutlined />
            </p>
            <p>{t("media.components.VoiceProfileCreateForm.clickOrDrag")}</p>
            <Typography.Text type="secondary">
              {t("media.components.VoiceProfileCreateForm.fileUploadWill")}
            </Typography.Text>
          </Dragger>
        </Flex>
      )}

      <Flex style={{ marginTop: 30 }}>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={createVoiceProfile}
          loading={isCreating}
          disabled={!canCreate}
        >
          {t("media.components.VoiceProfileCreateForm.cloneVoice")}
        </Button>
      </Flex>
    </Flex>
  );
};
